import React from 'react'
import NoteEditor from 'components/App/DesktopView/shared/Composer/Note/Editor'

export default class EditorView extends React.PureComponent {
  onChange = text => {
    const { content, onChange } = this.props
    if (text !== content) onChange(text)
  }

  editorKey = () => {
    const { ticketId } = this.props
    return `reply-editor-ticket-${ticketId}`
  }

  // expansion controls whether the editor should update if we expand
  // the content key.
  // If we expand, it should not update.
  // If we shrink, we should update.
  editorKeyExpansion = () => {
    const { draftId } = this.props
    return draftId || null
  }

  render() {
    const {
      content,
      onKeyDown,
      onMention,
      onFocus,
      onBlur,
      onInit,
    } = this.props

    return (
      <NoteEditor
        content={content}
        contentKey={this.editorKey()}
        contentKeyExpansion={this.editorKeyExpansion()}
        className="editor"
        onChange={this.onChange}
        onKeyDown={onKeyDown}
        onMention={onMention}
        onFocus={onFocus}
        onBlur={onBlur}
        onInit={onInit}
      />
    )
  }
}
