import { useSelector } from 'react-redux'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { isAdminRole } from 'util/agents'
import RuleLabel from './RuleLabel'
import AgentLabel from './AgentLabel'
import { getIntegrationName } from './util'

export default function Author({ id, name, role, email, provider }) {
  const currentUserId = useSelector(selectCurrentUserGlobalId)
  let author = name || email
  if (isAdminRole(role) && id === currentUserId) {
    author = 'You'
  } else if (role === 'Collaborator' && name) {
    author = `${name} (${email})`
  } else if (role === 'Rule') {
    author = <RuleLabel ruleId={id}>{name}</RuleLabel>
  } else if (role === 'Agent') {
    author = <AgentLabel agentId={id} />
  } else if (role === 'UserIntegration') {
    author = getIntegrationName(provider)
  }
  return author || null
}
