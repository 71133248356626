import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NewConvoHeader from 'components/App/DesktopView/shared/NewConvoHeader'
import ReplyEmailForm from 'components/App/DesktopView/CommunicationForms/Reply/Email'
import Scroller from 'components/Scroller'
import ImageLightbox from 'components/ImageLightbox'
import { doFetchCurrentContact } from 'ducks/crm/contacts/operations/fetching/doFetchCurrentContact'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { useDraft } from 'ducks/drafts2/hooks'
import { doOpenMainPage } from 'actions/pages'
import { useAi } from 'ducks/ai/hooks'
import { COMPOSER_TYPE } from 'ducks/ai/types'
import { styles as aIMessageStyles } from 'components/App/DesktopView/Layout/TicketInspector/Changesets/AIMessage/styles'
import { NEW_CONVERSATION_ID } from 'ducks/tickets/constants'
import { styles } from '../shared/styles'
import AIMessage from '../../Layout/TicketInspector/Changesets/AIMessage'

export default function NewEmailPage() {
  const dispatch = useDispatch()
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )

  const ticketId = NEW_CONVERSATION_ID
  const { draftId, loaded } = useDraft(ticketId, 'reply')

  const { requestState: composerRequestState } = useAi(COMPOSER_TYPE)
  const hasAIEditRequestState =
    composerRequestState.loading || composerRequestState.error

  useEffect(
    () => {
      dispatch(doFetchCurrentContact())
    },
    [dispatch, draftId]
  )

  useEffect(
    () => {
      if (!isAdminOrOwnerOrAgent) dispatch(doOpenMainPage())
    },
    [dispatch, isAdminOrOwnerOrAgent]
  )

  if (!loaded || !isAdminOrOwnerOrAgent) return null

  return (
    <React.Fragment>
      <NewConvoHeader isLogging={false} />
      <div css={styles.composeFormContainer}>
        <Scroller>
          <AIMessage className="grui mx-13 mt-13 mb-0" />
          <ReplyEmailForm
            ticketId={ticketId}
            css={hasAIEditRequestState && aIMessageStyles.hiddenComposer}
          />
        </Scroller>
      </div>
      <ImageLightbox />
    </React.Fragment>
  )
}
