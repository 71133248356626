import React from 'react'
import { useSelector } from 'react-redux'
import UpdateAppBanner from 'components/Banners/UpdateApp'
import { selectIsUpdateAvailable } from 'selectors/app'
import Switcher from './Switcher'
import styles from '../styles.less'

const MainCell = () => {
  const isUpdateAvailable = useSelector(selectIsUpdateAvailable)
  return (
    <div className={styles.mainCell}>
      {isUpdateAvailable && <UpdateAppBanner />}
      <Switcher />
    </div>
  )
}

export default MainCell
