import { useCallback, useMemo, forwardRef } from 'react'
import Link from 'redux-first-router-link'
import {
  selectConversationEventGroupById,
  selectConversationEventById,
} from 'ducks/tickets/selectors'
import { snoozeLabel, SNOOZED_INDEFINITELY } from 'util/snooze'
import { emptyObj } from 'util/objects'

import { useSelector } from 'react-redux'
import {
  CHANGE_TYPE_AGENT_CHANGED,
  CHANGE_TYPE_CHANNEL_CHANGED,
  CHANGE_TYPE_COMMENT_DELETED,
  CHANGE_TYPE_CONTACT_CHANGED,
  CHANGE_TYPE_CONTACT_OPENDED_MESSAGE,
  CHANGE_TYPE_CONVERSATION_DELETED,
  CHANGE_TYPE_CONVERSATION_MERGED,
  CHANGE_TYPE_CONVERSATION_RESTORED,
  CHANGE_TYPE_CONVERSATION_STARRED,
  CHANGE_TYPE_CONVERSATION_UNSTARRED,
  CHANGE_TYPE_EMAIL_MESSAGE,
  CHANGE_TYPE_INTEGRATION_ADDED,
  CHANGE_TYPE_INTEGRATION_EXTERNAL_CHANGE,
  CHANGE_TYPE_INTEGRATION_REMOVED,
  CHANGE_TYPE_SHOPIFY_EXTERNAL_CHANGE,
  CHANGE_TYPE_SNOOZED,
  CHANGE_TYPE_STATE_CHANGED,
  CHANGE_TYPE_SUBJECT_CHANGED,
  CHANGE_TYPE_TAGGED,
  CHANGE_TYPE_TEAM_CHANGED,
  CHANGE_TYPE_UNSNOOZED,
  CHANGE_TYPE_UNTAGGED,
  CLOSED,
  OPENED,
} from 'ducks/tickets/constants'
import { fromToUserText } from 'ducks/tickets/utils/message'
import selectIntegrationSettingsByProvider from 'ducks/integrations/selectors/selectIntegrationSettingsByProvider'
import { selectCurrentAgentById } from 'ducks/agents/selectors'
import { selectContactById } from 'ducks/crm/contacts'
import { getRawId } from 'util/globalId'
import IntegrationAction from '../IntegrationAction'
import Author from '../Author'
import GroupLabel from '../GroupLabel'
import TagLabel from '../TagLabel'
import AgentLabel from '../AgentLabel'
import ChannelLabel from '../ChannelLabel'
import ProviderIcon from '../ProviderIcon'
import IntegrationExternalAction, {
  shouldShowIntegrationAction,
} from '../IntegrationExternalAction'
import { shopifyChangeText } from '../util/shopifyChangeText'

const ByLine = forwardRef(
  ({ eventGroupId, eventId, onMouseEnter, openRuleEditDrawer }, ref) => {
    const integrationSettings = useSelector(selectIntegrationSettingsByProvider)
    const { isForward, isNote, isFirstMessage } = useSelector(
      state => selectConversationEventGroupById(state, eventGroupId) || emptyObj
    )
    const event = useSelector(state =>
      selectConversationEventById(state, eventId, null, true)
    )

    const {
      actor,
      change: {
        __typename: type,
        changeRuleId,
        to: rawChangeTo,
        from: changeFrom,
        provider: changeProvider,
        title: changeTitle,
        url: changeUrl,
        changeType: changeChangeType,
        link: changeLink,
        externalId: changeExternalId,
        meta: changeMeta,
        until: changeUntil,
        tag: changeTag,
      },
    } = event

    const changeTo = useSelector(state => {
      // eslint-disable-next-line no-underscore-dangle
      if (rawChangeTo?.__typename === 'Agent') {
        return selectCurrentAgentById(state, rawChangeTo.id)
        // eslint-disable-next-line no-underscore-dangle
      } else if (rawChangeTo?.__typename === 'Contact') {
        return selectContactById(state, rawChangeTo.id)
      }
      return rawChangeTo
    })

    const {
      id: actorId,
      name: actorName,
      role: actorRole,
      avatarUrl: actorAvatarUrl,
      provider: actorProvider,
    } =
      actor || emptyObj

    let author = (
      <span className="author">
        <Author
          id={actorId}
          role={actorRole}
          name={actorName}
          avatarUrl={actorAvatarUrl}
          provider={actorProvider}
        />
      </span>
    )
    let operation = null
    let icon = null

    const handleOpenRuleEditDrawer = useCallback(
      e => {
        openRuleEditDrawer(e, changeRuleId)
      },
      [openRuleEditDrawer, changeRuleId]
    )

    const recipients = useMemo(
      () => {
        if (!changeTo) return []
        const changeToArray = Array.isArray(changeTo) ? changeTo : [changeTo]

        return changeToArray
          .map(recipient => {
            if (isForward && recipient.name) {
              return `${recipient.name} (${recipient.email})`
            }
            return recipient.name || recipient.email
          })
          .join(', ')
      },
      [changeTo, isForward]
    )

    switch (type) {
      case CHANGE_TYPE_AGENT_CHANGED:
      case CHANGE_TYPE_TEAM_CHANGED:
        if (!changeTo) {
          operation = <span className="action-verb">unassigned</span>
        } else if (getRawId(changeTo.id) === getRawId(actorId)) {
          operation = (
            <span>
              <span className="action-verb">took</span> ownership
            </span>
          )
        } else if (type === CHANGE_TYPE_TEAM_CHANGED) {
          operation = (
            <>
              <span className="action-verb">assigned</span> to{' '}
              <GroupLabel groupId={changeTo.id} />
            </>
          )
        } else {
          operation = (
            <>
              <span className="action-verb">assigned</span> to{' '}
              <AgentLabel agentId={changeTo.id} />
            </>
          )
        }
        break
      case CHANGE_TYPE_CHANNEL_CHANGED:
        operation = (
          <>
            <span className="action-verb">moved</span>
            {changeFrom ? ' from ' : ' to '}
            <ChannelLabel channelId={changeFrom?.id || changeTo?.id} />
          </>
        )
        break
      case CHANGE_TYPE_CONTACT_CHANGED:
        operation = (
          <span>
            <span className="action-verb">changed</span> user
            {fromToUserText('to', changeTo)}
          </span>
        )
        break
      case CHANGE_TYPE_CONTACT_OPENDED_MESSAGE:
        // The opened message is special because the
        operation = author
        author = (
          <>
            <span className="action-verb">Seen</span> by{' '}
          </>
        )
        break
      case CHANGE_TYPE_CONVERSATION_DELETED:
        operation = (
          <span>
            <span className="action-verb">moved</span> to trash
          </span>
        )
        break
      case CHANGE_TYPE_CONVERSATION_RESTORED:
        operation = (
          <span>
            <span className="action-verb">restored</span> from trash
          </span>
        )
        break
      case CHANGE_TYPE_INTEGRATION_ADDED:
      case CHANGE_TYPE_INTEGRATION_REMOVED:
        icon = <ProviderIcon provider={changeProvider} />
        operation = (
          <IntegrationAction
            provider={changeProvider}
            removed={type === CHANGE_TYPE_INTEGRATION_REMOVED}
            title={changeTitle}
            url={changeUrl}
            changeType={type}
          />
        )
        break
      case CHANGE_TYPE_INTEGRATION_EXTERNAL_CHANGE:
        if (!shouldShowIntegrationAction(event, integrationSettings)) {
          return null
        }
        icon = <ProviderIcon provider={changeProvider} />
        operation = <IntegrationExternalAction action={event} />
        break
      case CHANGE_TYPE_CONVERSATION_MERGED:
        operation = (
          <span>
            <span className="action-verb">merged</span> conversation
          </span>
        )
        break
      case CHANGE_TYPE_SHOPIFY_EXTERNAL_CHANGE:
        // chat events are rendered through:
        // components/App/DesktopView/Layout/TicketInspector/Changesets/ExpandedMessage/IntegrationAction
        icon = <ProviderIcon provider="shopify_v2" />
        operation = (
          <span className="action-verb">
            {shopifyChangeText(changeChangeType)}&nbsp;
            <Link target="_blank" to={changeLink}>
              {changeMeta?.orderName || `#${changeExternalId}`}
            </Link>
          </span>
        )
        break
      case CHANGE_TYPE_SNOOZED:
        if (changeUntil) {
          operation = (
            <span>
              <span className="action-verb">snoozed</span> until{' '}
              {snoozeLabel(changeUntil, false)}
            </span>
          )
        } else {
          operation = (
            <span>
              <span className="action-verb">snoozed</span>{' '}
              {snoozeLabel(SNOOZED_INDEFINITELY)}
            </span>
          )
        }
        break
      case CHANGE_TYPE_UNSNOOZED:
        operation = <span className="action-verb">unsnoozed</span>
        break
      case CHANGE_TYPE_CONVERSATION_STARRED:
        operation = (
          <span>
            <span className="action-verb">added</span> star
          </span>
        )
        break
      case CHANGE_TYPE_CONVERSATION_UNSTARRED:
        operation = (
          <span>
            <span className="action-verb">removed</span> star
          </span>
        )
        break
      case CHANGE_TYPE_SUBJECT_CHANGED:
        if (changeFrom) {
          operation = (
            <span>
              <span className="action-verb">changed</span> title from{' '}
              {changeFrom}
            </span>
          )
        } else {
          operation = (
            <span>
              <span className="action-verb">changed</span> title
            </span>
          )
        }
        break
      case CHANGE_TYPE_STATE_CHANGED:
        if (changeTo === OPENED) {
          operation = (
            <span>
              <span className="action-verb">reopened</span> the conversation
            </span>
          )
        } else if (changeTo === CLOSED) {
          operation = (
            <span>
              <span className="action-verb">closed</span> the conversation
            </span>
          )
        } else {
          operation = (
            <span>
              <span className="action-verb">marked</span> as{' '}
              {changeTo.toLowerCase()}
            </span>
          )
        }

        break
      case CHANGE_TYPE_TAGGED:
        operation = (
          <span>
            <span className="action-verb">added tag</span>&nbsp;
            <TagLabel labelId={changeTag.id} />
          </span>
        )
        break
      case CHANGE_TYPE_UNTAGGED:
        operation = (
          <span>
            <span className="action-verb">removed tag</span>&nbsp;
            <TagLabel labelId={changeTag.id} />
          </span>
        )
        break
      case CHANGE_TYPE_EMAIL_MESSAGE:
        if (isNote) {
          if (changeRuleId) {
            operation = (
              <span className="operation note">
                <span className="action-verb">
                  added an{' '}
                  <span
                    className="action-verb-topic"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={handleOpenRuleEditDrawer}
                  >
                    automated note
                  </span>
                </span>
              </span>
            )
          } else {
            operation = (
              <span className="operation note">
                <span className="action-verb">added</span> a note
              </span>
            )
          }
        } else if (!isNote && isFirstMessage) {
          operation = (
            <span className="operation message">
              <span className="action-verb">started</span> a conversation
            </span>
          )
        } else {
          try {
            if (changeRuleId) {
              let operationContent = null

              if (isForward) {
                operationContent = (
                  <span className="action-verb">
                    <span
                      className="action-verb-topic"
                      onClick={handleOpenRuleEditDrawer}
                    >
                      forwarded this conversation by rule
                    </span>
                  </span>
                )
              } else {
                operationContent = (
                  <span className="action-verb">
                    sent an{' '}
                    <span
                      className="action-verb-topic"
                      onClick={handleOpenRuleEditDrawer}
                    >
                      automated reply
                    </span>
                  </span>
                )
              }

              operation = (
                <span className="operation message">
                  {operationContent} to {recipients}
                </span>
              )
            } else {
              const actionText = isForward ? 'forwarded' : 'replied'
              operation = (
                <span className="operation message">
                  <span className="action-verb">{actionText}</span> to{' '}
                  {recipients}
                </span>
              )
            }
          } catch (e) {} // eslint-disable-line no-empty
        }
        break
      case CHANGE_TYPE_COMMENT_DELETED:
        operation = (
          <span>
            <span className="action-verb">deleted</span> note
          </span>
        )
        break
      default:
        operation = type
        break
    }

    return (
      <div ref={ref} className="byline" onMouseEnter={onMouseEnter}>
        {icon && <>{icon} </>}
        {author}
        {operation && <> {operation}</>}
      </div>
    )
  }
)

export default ByLine
