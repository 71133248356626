import { connect } from 'react-redux'

import { doUploadFiles } from 'actions/attachments'

import {
  selectAttachmentsByDraftId,
  selectDraftAssignmentLabelByTicketId,
  selectDraftByTicketId,
} from 'ducks/drafts2/selectors'
import { doRealtimeAgentStartTicketTypingNotification } from 'actions/realtime'
import {
  debouncedHandleDraftChange,
  handleDraftChange,
  doDeleteDraft,
  doSendReplyDraft,
} from 'ducks/drafts2/operations'
import { doUpdateEditorVisibility } from 'ducks/editor/operations'

import { selectShowKbSearchOnInboxEditor } from 'ducks/currentUser/selectors/preferences/selectShowKbSearchOnInboxEditor'
import {
  selectShowMentionSelector,
  selectIsMentioningAgent,
  selectSelectedMentionSelectorAgentId,
  selectSuppressMentionSelector,
} from 'selectors/mentions'
import { doOpenTicketPage } from 'actions/pages'
import { doUpdateAgentMention } from 'actions/app'
import { doMentionAgent } from 'actions/tickets'
import { selectIsComposingNewTicket } from 'selectors/tickets/current/selectIsComposingNewTicket'
import { selectPrefersAiEnabled } from 'selectors/app/selectAccountPreferences'

import View from './view'

const DRAFT_TYPE = 'note'
const select = () => {
  return (state, props) => {
    const { ticketId } = props
    const draft = selectDraftByTicketId(state, ticketId, DRAFT_TYPE) || {}
    const {
      id: draftId,
      assigneeGroupId,
      serverVersion,
      version,
      to,
      title,
      mailboxId,
      replyType,
      isSendable,
      notSendableReason,
      phoneNumber: phone,
    } = draft

    const showKbSearch = selectShowKbSearchOnInboxEditor(state)

    return {
      draftId,
      syncable: !!draftId,
      isSynced: serverVersion === version,
      assignmentLabel: selectDraftAssignmentLabelByTicketId(
        state,
        ticketId,
        DRAFT_TYPE
      ),
      assigneeGroupId,
      mailboxId,
      to,
      subject: title || '',
      phone,
      replyType: replyType || 'reply-all',
      isSendable,
      notSendableReason,
      showKbSearch,
      attachments: selectAttachmentsByDraftId(state, draftId),
      showMentionSelector: selectShowMentionSelector(state),
      isMentioning: selectIsMentioningAgent(state),
      selectedMentionSelectorAgentId: selectSelectedMentionSelectorAgentId(
        state
      ),
      suppressMentionSelector: selectSuppressMentionSelector(state),
      isNewTicket: selectIsComposingNewTicket(state),
      prefersAiEnabled: selectPrefersAiEnabled(state),
    }
  }
}

const perform = dispatch => {
  return {
    deleteDraft: (ticketId, draftId) => {
      if (draftId) {
        const msg = 'Are you sure you want to delete this draft?'
        if (!window.confirm(msg)) return // eslint-disable-line no-alert
        dispatch(doDeleteDraft(ticketId, draftId))
      } else {
        dispatch(doUpdateEditorVisibility(false))
      }
    },
    debouncedHandleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId, oldBody } = opts
      const messageId = null // not used yet
      debouncedHandleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields,
        oldBody
      )
    },
    handleDraftChange: (ticketId, prop, val, opts) => {
      const fields = { [prop]: val }
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields
      )
    },
    handleDraftMultiChange: (ticketId, fields, opts) => {
      const { draftId } = opts
      const messageId = null // not used yet
      handleDraftChange(
        dispatch,
        DRAFT_TYPE,
        draftId,
        ticketId,
        messageId,
        fields
      )
    },
    sendDraft: draftId => {
      dispatch(doSendReplyDraft(draftId))
    },
    uploadFiles: (...args) => {
      dispatch(doUploadFiles(...args))
    },
    realtimeAgentStartTicketTypingNotification: (...args) => {
      dispatch(doRealtimeAgentStartTicketTypingNotification(...args))
    },
    doUpdateAgentMention: (...args) => {
      dispatch(doUpdateAgentMention(...args))
    },
    openTicketPageWithReplying: ticketId => {
      dispatch(doOpenTicketPage(ticketId, { replying: true }))
    },
    doMentionAgent: (...args) => {
      dispatch(doMentionAgent(...args))
    },
  }
}

export default connect(select, perform)(View)
