import React, { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { strftime, isThisYear } from 'util/date'
import { selectConversationEventGroupById } from 'ducks/tickets/selectors'
import { emptyObj } from 'util/objects'
import {
  isOutsideCommunication,
  isTicketActionChangeBodyEmpty,
} from 'ducks/tickets/utils/message'
import { selectTicketEventsRequestByEventGroupId } from 'ducks/tickets/selectors/selectTicketEventsRequestByEventGroupId'

import BodySearchMarker from '../BodySearchMarker'
import Avatar from '../ExpandedMessage/Avatar'
import Author from '../ExpandedMessage/Author'

const CollapsedMessage = ({ eventGroupId, onClick }) => {
  const [bodyNode, setBodyNode] = useState(null)
  const {
    changesetId,
    hasAttachments,
    isForward,
    isNote,
    actor,
    summary: { bodyPlainText: snippet, editedAt, formattedCreatedAt },
  } = useSelector(
    state =>
      selectConversationEventGroupById(state, eventGroupId, null, true) ||
      emptyObj
  )
  const { loading: isLoading } = useSelector(state =>
    selectTicketEventsRequestByEventGroupId(state, eventGroupId)
  )

  const {
    id: actorId,
    name: actorName,
    role: actorRole,
    avatarUrl: actorAvatarUrl,
    provider: actorProvider,
  } =
    actor || emptyObj

  const isOutside = isOutsideCommunication(isForward, actorRole)

  const onBodyNode = useCallback(node => {
    setBodyNode(!node || !node.querySelectorAll ? null : node)
  }, [])

  const editedTooltip = useMemo(
    () => {
      if (!editedAt) return null

      const thisYear = isThisYear(editedAt)

      if (thisYear) {
        return `Last edited ${strftime('%d %b, %l:%M %p', editedAt)}`
      }

      return `Last edited ${strftime('%d %b %Y, %l:%M %p', editedAt)}`
    },
    [editedAt]
  )

  const isBodyEmpty = isTicketActionChangeBodyEmpty(snippet)

  return (
    <div
      id={`changeset-${changesetId}`}
      className={cn('changeset collapsed', {
        forward: isOutside,
      })}
    >
      <div className="action-container">
        <div
          className={cn('action', {
            message: !isNote,
            note: isNote,
            forward: isOutside,
          })}
          onClick={onClick}
        >
          <Avatar
            id={actorId}
            type={actorRole}
            name={actorName}
            avatarUrl={actorAvatarUrl}
          />
          <div className="contents">
            <div className="header">
              <Author
                id={actorId}
                role={actorRole}
                name={actorName}
                avatarUrl={actorAvatarUrl}
                provider={actorProvider}
              />
              <div className="right-side">
                <div className="icons">
                  {hasAttachments && <div className="Icon Icon-attachment" />}
                </div>
                {editedTooltip && (
                  <Tooltip
                    title={editedTooltip}
                    position="top"
                    strategy="fixed"
                  >
                    <span className="edited-datetime">Edited</span>
                  </Tooltip>
                )}
                <div className="datetime">{formattedCreatedAt}</div>
              </div>
            </div>
            <div className="snippet">
              {isLoading && (
                <div className="placeholder">
                  <div className="placeholder-content" />
                  <div className="placeholder-content" />
                  <div className="placeholder-content" />
                </div>
              )}
              {!isLoading &&
                !isBodyEmpty &&
                snippet && (
                  <>
                    <SanitizedHTML
                      as="span"
                      html={snippet.replace(/&nbsp;/g, ' ')}
                      onBodyNode={onBodyNode}
                    />
                    <BodySearchMarker node={bodyNode} />
                  </>
                )}
              {!isLoading &&
                isBodyEmpty && (
                  <div className="empty-body">No content in this message</div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollapsedMessage
