import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_CHANNELS_AUTHENTICATE,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE_IMAP,
} from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import NoticeBar from 'components/NoticeBar'
import { selectMailboxByStates } from 'ducks/mailboxes/selectors/selectMailboxByStates'
import { selectCurrentMailboxId } from 'ducks/mailboxes/selectors/selectCurrentMailboxId'
import { selectMailbox } from 'ducks/mailboxes/selectors/selectMailbox'
import { getChannelTypeByTypeValue } from 'ducks/channels/utils'
import { isIMAPChannelType } from 'ducks/channels/channelTypes'
import { CHANNEL_STATE, CHANNEL_TYPE } from 'ducks/mailboxes/constants'

import styles from './styles.less'

export default function OAuthEmailChannelDisconnected({ isManager }) {
  const ticketListMailboxId = useSelector(selectCurrentMailboxId)
  const disconnectedMailboxes = useSelector(state =>
    selectMailboxByStates(state, [CHANNEL_STATE.UNCONFIRMED])
  )
  const disconnectedIMAPMailboxes = useSelector(state =>
    selectMailboxByStates(state, [CHANNEL_STATE.UNCONFIRMED], null, [
      CHANNEL_TYPE.IMAP,
    ])
  )
  const firstMailbox =
    disconnectedMailboxes[0] || disconnectedIMAPMailboxes[0] || {}
  const currentMailboxId = ticketListMailboxId || firstMailbox.id
  const currentMailbox = useSelector(state =>
    selectMailbox(state, currentMailboxId)
  )
  const { drawerId, openDrawer: openAddDrawer } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_AUTHENTICATE,
    lazyLoad: true,
    closeIgnoresStack: true,
  })

  const {
    drawerId: imapAuthDrawerId,
    openDrawer: openIMAPAuthDrawer,
  } = useDrawer({
    type: DRAWER_TYPE_CHANNELS_AUTHENTICATE_IMAP,
    lazyLoad: true,
    closeIgnoresStack: true,
  })

  const onOpen = useCallback(
    () => {
      const isIMAPChannel = isIMAPChannelType(currentMailbox.type)
      const authDrawerId = isIMAPChannel ? imapAuthDrawerId : drawerId
      const openDrawer = isIMAPChannel ? openIMAPAuthDrawer : openAddDrawer
      openDrawer(currentMailboxId, {
        query: {
          ...buildDrawerQueryParam(
            authDrawerId,
            'drawerChannelType',
            getChannelTypeByTypeValue(currentMailbox.type)
          ),
          ...buildDrawerQueryParam(authDrawerId, 'drawerSource', 'inbox'),
        },
      })
    },
    [
      openAddDrawer,
      currentMailboxId,
      drawerId,
      imapAuthDrawerId,
      openIMAPAuthDrawer,
      currentMailbox.type,
    ]
  )

  const mailboxName = !ticketListMailboxId ? ` ${firstMailbox.name}` : ''

  let callToActionContent = `Please contact your administrator to reconnect your ${app.t(
    'mailbox'
  )} now.`
  if (isManager) {
    callToActionContent = (
      <a className={styles.demoBarLinkInlineWarning} onClick={onOpen}>
        Please reconnect your{mailboxName} {app.t('mailbox')} now.
      </a>
    )
  }

  return (
    <NoticeBar
      type="warning"
      flagName={`seen-gmail-disconnected-notice-${currentMailboxId || ''}`}
      dismissible
      isSessionFlag
    >
      Oh no, looks like this {app.t('mailbox')} has been disconnected!&nbsp;
      {callToActionContent}
    </NoticeBar>
  )
}
