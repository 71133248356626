import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doFetchTicket } from 'ducks/tickets/actions/doFetchTicket'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import TicketNotFound from 'subapps/ticketing/components/TicketInspector/TicketNotFound'
import TicketDeleted from 'subapps/ticketing/components/TicketInspector/TicketNotFound/TicketDeleted'
import NoAccess from 'subapps/ticketing/components/TicketInspector/NoAccess'
import { selectTicketRequestByConversationId } from 'ducks/tickets/selectors/selectTicketRequestByConversationId'
import { selectTicketEventsGroupRequestByConversationId } from 'ducks/tickets/selectors/selectTicketEventsGroupRequestByConversationId'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { doFetchTicketEventGroups } from 'ducks/tickets/actions/doFetchTicketEventGroups'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import { doFetchTickets } from 'ducks/tickets/actions'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { doFetchFolderCounts } from 'ducks/searches/operations/doFetchFolderCounts'
import { doOpenTicketPage } from 'actions/pages'
import { getRawId } from 'util/globalId'
import { doRedirectToCollectionAndFolderById } from 'ducks/folders/operations/collections'
import {
  selectIsOnTicketCommentPage,
  selectIsOnTicketPage,
} from 'selectors/location'
import { isBlank } from 'util/strings'
import NormalState from './NormalState'
import EmptyState from './EmptyState'

const TicketInspector = props => {
  const { ticketId: conversationId } = props
  const dispatch = useDispatch()
  const [loadedConversationId, setLoadedConversationId] = useState(null)
  const queryId = useSelector(selectCurrentQueryId)
  const { channel = [] } = useSelector(selectCurrentQuery)
  const channelId = channel[0]
  const is3ColumnView = useSelector(selectPrefersClassicView)
  const { loaded: searchLoaded, loading: searchLoading } = useSelector(state =>
    selectSearchByQueryId(state, queryId)
  )
  const ticket = useSelector(state =>
    selectCurrentConversationById(state, conversationId)
  )
  const { error, loaded, loading, untried } = useSelector(state =>
    selectTicketRequestByConversationId(state, conversationId)
  )
  const isOnTicketPage = useSelector(selectIsOnTicketPage)
  const isTicketCommentPage = useSelector(selectIsOnTicketCommentPage)

  const { untried: eventGroupLoadUntried } = useSelector(state =>
    selectTicketEventsGroupRequestByConversationId(state, conversationId)
  )
  const noAccessError = useMemo(
    () => {
      return error?.errors?.find(e => e?.extensions?.code === 'UNAUTHORIZED')
    },
    [error]
  )

  const deletedError = useMemo(
    () => {
      return error?.errors?.find(
        e => e?.extensions?.code === 'RESOURCE_DELETED'
      )
    },
    [error]
  )

  const mergeError = useMemo(
    () => {
      return error?.errors?.find(e => e?.extensions?.code === 'RESOURCE_MERGED')
    },
    [error]
  )

  useEffect(
    () => {
      dispatch(
        doFetchTicket({
          conversationId,
          channelType: MAILBOX_CHANNEL_TYPE,
        })
      )
    },
    [dispatch, conversationId]
  )

  useEffect(
    () => {
      // 1) When we're on 4 column view, the ticket list will handle loading its conversations
      // 2) We'll first wait for the individual ticket call to finish, then load
      //    the list information to make sure the 2 requests arent competing for bandwith
      if (
        is3ColumnView &&
        (error || loaded) &&
        !eventGroupLoadUntried &&
        !searchLoaded &&
        !searchLoading
      ) {
        // Fetch the conversation list specified in the url
        dispatch(doFetchTickets({ isReload: false, loadFirst: true }))

        // If the current conversation list is scoped to a mailbox, do another hit
        // so pull the global non-channel scoped counts
        if (channelId) {
          dispatch(
            doFetchFolderCounts({
              channelType: MAILBOX_CHANNEL_TYPE,
            })
          )
        }
        // Finally pull the counts for the current mailbox
        dispatch(
          doFetchFolderCounts({ channelId, channelType: MAILBOX_CHANNEL_TYPE })
        )
      }
    },
    [
      dispatch,
      searchLoaded,
      searchLoading,
      error,
      loaded,
      is3ColumnView,
      channelId,
      eventGroupLoadUntried,
    ]
  )

  useEffect(
    () => {
      if (!untried && !error && loaded && eventGroupLoadUntried) {
        dispatch(doFetchTicketEventGroups(conversationId))
      }
    },
    [
      dispatch,
      error,
      loading,
      untried,
      loaded,
      conversationId,
      eventGroupLoadUntried,
    ]
  )

  // When switcing between conversations we dont want all html to get unmounted and re-mounted causing a very
  // visual flicker. So what we're doing is saving the loaded conversation id and that'll continue to show
  // until the next conversation gets loaded in. Once the conversation is loaded we'll either render the
  // ticket inspector or one of the error states below. The exception to this rule is first load where
  // there is no loaded converastion. In this situation we'll render an empty state
  useEffect(
    () => {
      if (!mergeError && (loaded || error)) {
        setLoadedConversationId(conversationId)
      }
    },
    [error, loaded, mergeError, conversationId]
  )

  useEffect(
    () => {
      if (isBlank(queryId) && (isOnTicketPage || isTicketCommentPage)) {
        dispatch(
          doRedirectToCollectionAndFolderById(null, null, {
            ignoreLast: false,
            preservePage: true,
          })
        )
      }
    },
    [dispatch, queryId, isOnTicketPage, isTicketCommentPage]
  )

  if (error) {
    // No access
    if (noAccessError) {
      return <NoAccess mailboxId={noAccessError.extensions.channel_id} />
    } else if (deletedError) {
      return (
        <TicketDeleted
          actorId={deletedError.extensions.actor_id}
          actorType={deletedError.extensions.actor_type}
        />
      )
    } else if (mergeError) {
      dispatch(
        doOpenTicketPage(getRawId(mergeError.extensions.new_conversation_id))
      )
      return <EmptyState />
    }
    // There is an error, but we dont know what is
    // Fallback to 404 for this case. We should probably create a generic
    // error state
    return <TicketNotFound />
  } else if (!loadedConversationId) {
    return <EmptyState />
  } else if (loaded && !ticket) {
    return <TicketNotFound />
  }

  return <NormalState ticketId={loadedConversationId} />
}

export default TicketInspector
