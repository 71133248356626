import React, { useMemo, useRef, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useResizeDetector } from 'react-resize-detector'

import { selectCurrentTicketMailbox } from 'selectors/tickets'

const Addresses = ({
  isNote,
  change,
  mailboxIndicatorNeeded,
  expanded,
  isIncomingMessage,
  isForward,
}) => {
  const [isAddressOverflow, setIsAddressOverflow] = useState(false)
  const targetRef = useRef()
  const mailbox = useSelector(selectCurrentTicketMailbox)

  const onResize = useCallback(
    () => {
      if (expanded) return

      const { current } = targetRef
      if (current) {
        setIsAddressOverflow(current.scrollWidth > current.clientWidth)
      }
    },
    [expanded]
  )

  useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 300,
    onResize,
    refreshOptions: {
      leading: false,
      trailing: true,
    },
    targetRef,
  })

  const tos = change.to && change.to.length > 0 ? change.to : null
  const displayToAddress = !mailboxIndicatorNeeded && !isNote && tos
  const ccs = change.cc && change.cc.length > 0 ? change.cc : null
  const bccs = change.bcc && change.bcc.length > 0 ? change.bcc : null

  const sortAddresses = (
    addresses,
    isExpanded,
    skipAgents = false,
    emailToUpdate
  ) => {
    if (!addresses) return []
    return addresses
      .map(adr => {
        if (skipAgents && adr.type === 'Agent') return null
        const email = emailToUpdate || adr.email || 'Unknown Email'
        let address = adr.name ? adr.name : email
        if (isExpanded) {
          address = adr.name ? `${adr.name} (${email})` : email
        }

        return {
          id: adr.id,
          address,
        }
      })
      .filter(Boolean)
      .sort((a, b) => a.name > b.name)
  }

  const sortedTos = useMemo(
    () => {
      // eslint-disable-next-line camelcase
      const replaceAgentsWithMailbox =
        mailbox && mailbox.fromName === 'Mailbox' && !isForward

      const sorted = sortAddresses(
        tos,
        expanded,
        replaceAgentsWithMailbox,
        // Don't show agents' emails in the to section if it's an incoming message
        // show mailbox's email instead
        isIncomingMessage && mailbox?.email
      )
      if (replaceAgentsWithMailbox) {
        let address = mailbox.name ? mailbox.name : mailbox.email
        if (expanded) {
          address = mailbox.name
            ? `${mailbox.name} (${mailbox.email})`
            : mailbox.email
        }
        const exists = !!sorted.find(({ id }) => id === mailbox.id)
        if (!exists && isIncomingMessage) {
          // NOTE (jscheel): Would be better to use gid, becasue this could result
          // in collisions, but we don't use gid in any of the others right now.
          sorted.unshift({ id: mailbox.id, address })
        }
      }

      return sorted
    },
    [tos, expanded, mailbox, isIncomingMessage, isForward]
  )

  const sortedCcs = useMemo(
    () => {
      return sortAddresses(ccs, expanded)
    },
    [ccs, expanded]
  )

  const sortedBccs = useMemo(
    () => {
      return sortAddresses(bccs, expanded)
    },
    [bccs, expanded]
  )

  if (!displayToAddress && !ccs && !bccs) return null

  return (
    <div className="address-and-more">
      <span className="address-section-inner" ref={targetRef}>
        {displayToAddress &&
          tos && (
            <span className="addresses">
              <span className="address-title">To:&nbsp;</span>
              {sortedTos.map(({ id, address }) => (
                <span className="address" key={id}>
                  {address}
                </span>
              ))}
            </span>
          )}
        {ccs && (
          <span className="addresses">
            <span className="address-title">cc:&nbsp;</span>
            {sortedCcs.map(({ id, address }) => (
              <span className="address" key={id}>
                {address}
              </span>
            ))}
          </span>
        )}
        {bccs && (
          <span className="addresses">
            <span className="address-title">bcc:&nbsp;</span>
            {sortedBccs.map(({ id, address }) => (
              <span className="address" key={id}>
                {address}
              </span>
            ))}
          </span>
        )}
      </span>
      {isAddressOverflow && (
        <span className="more hidden-in-print">+ more</span>
      )}
    </div>
  )
}

export default Addresses
