import { selectConversationEventById } from 'ducks/tickets/selectors'
import { useSelector } from 'react-redux'
import ByLine from './ByLine'

export default function Action({ eventGroupId, eventId }) {
  const { formattedCreatedAt } = useSelector(state =>
    selectConversationEventById(state, eventId)
  )

  return (
    <div className="action subsequent">
      <div className="contents">
        <div className="header">
          <div className="author">
            <ByLine eventGroupId={eventGroupId} eventId={eventId} />
          </div>

          <div className="right-side">
            <div className="datetime">{formattedCreatedAt}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
