import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { propFunc } from 'util/functions'
import { isForwarding } from 'util/draft'
import { selectIsComposingNewTicket } from 'selectors/tickets/current/selectIsComposingNewTicket'
import { CHANNEL_TYPE } from 'ducks/mailboxes/constants'

import ToRow from '../ToRow'
import CcRow from '../CcRow'
import BccRow from '../BccRow'
import FromRow from '../FromRow'
import SimpleRow from '../SimpleRow'
import Recipients from '../Recipients'
import { styles } from './styles'
import Actions from '../Actions'
import { FocusContext } from './FocusContext'
import { useHeaderController } from './useHeaderController'
import DraggableRowsWrapper from './DraggableRowsWrapper'

const Expanded = ({
  children, // i.e. FormSwitcherButton
  className,
  draft,
  draftType,
  forwarding,
  mailboxId,
  onMailboxClick,
  onSubjectChange,
  onPhoneChange,
  onNameChange,
  recipientsTo,
  recipientsCc,
  recipientsBcc,
  recipientsOpenUpward,
  subject,
  phone,
  name,
  ticketId,
  isNote,
}) => {
  const isNewTicket = useSelector(selectIsComposingNewTicket)
  const isForwardingType = isForwarding(draftType) || forwarding

  const {
    state,
    allRecipients,
    onActionClick,
    expand,
    getFocusableRef,
    setFocus,
    advanceFocus,
    retreatFocus,
    onToRecipientUpdated,
    getFocusedRow,
    dispatch,
  } = useHeaderController({
    ticketId,
    isNewTicket,
    isForwardingType,
    toRecipients: recipientsTo,
    ccRecipients: recipientsCc,
    bccRecipients: recipientsBcc,
    phone,
    subject,
  })
  const rowKeyPrefix = [ticketId, isForwardingType && CHANNEL_TYPE.FORWARDING]
    .filter(Boolean)
    .join('-')

  const actions = useMemo(
    () => {
      return (
        <div className="grui flex actions">
          <Actions
            hideFrom
            hideName={
              state.isNameDirty || state.rowVisibility.name || isForwardingType
            }
            hidePhone={!isNewTicket || !isNote || state.rowVisibility.phone}
            hideCc={state.rowVisibility.cc}
            hideBcc={state.rowVisibility.bcc}
            hideSubject={state.rowVisibility.subject}
            onClick={onActionClick}
          />
        </div>
      )
    },
    [
      state.rowVisibility,
      state.isNameDirty,
      isNewTicket,
      isNote,
      onActionClick,
      isForwardingType,
    ]
  )

  const handleNameChange = useCallback(
    (...args) => {
      dispatch({ type: 'SET_NAME_DIRTY_STATE', payload: { dirty: true } })
      onNameChange(...args)
    },
    [onNameChange, dispatch]
  )

  const toEmail = recipientsTo ? recipientsTo[0]?.email : null

  useEffect(
    () => {
      dispatch({ type: 'SET_NAME_DIRTY_STATE', payload: { dirty: false } })
    },
    [toEmail, dispatch]
  )

  const indented = useMemo(
    () => {
      return React.Children.toArray(children).filter(Boolean).length > 0
    },
    [children]
  )

  const onClickRecipients = useCallback(
    e => {
      e.stopPropagation()
      expand()
    },
    [expand]
  )

  const focusContextValues = useMemo(
    () => {
      return {
        getFocusableRef,
        setFocus,
        advanceFocus,
        retreatFocus,
        onToRecipientUpdated,
        getFocusedRow,
      }
    },
    [
      getFocusableRef,
      setFocus,
      advanceFocus,
      retreatFocus,
      onToRecipientUpdated,
      getFocusedRow,
    ]
  )

  return (
    <FocusContext.Provider value={focusContextValues}>
      <div className={cn('grui flex flex-col w-100', className)}>
        {state.rowVisibility.from &&
          state.isEditing && (
            <div css={styles.rowWrapper}>
              <FromRow
                draft={draft}
                mailboxId={mailboxId}
                onMailboxClick={onMailboxClick}
                isNewTicket={isNewTicket}
              >
                {children}
              </FromRow>
              {state.actionsRow === 'from' && actions}
            </div>
          )}
        {!state.isEditing && (
          <Recipients
            recipients={allRecipients}
            onClickRecipients={onClickRecipients}
          >
            {children}
          </Recipients>
        )}
        {state.isEditing && (
          <DraggableRowsWrapper
            ticketId={ticketId}
            isForwarding={forwarding}
            recipientsTo={recipientsTo}
            recipientsCc={recipientsCc}
            recipientsBcc={recipientsBcc}
          >
            <div
              // pb-1: make sure the recipent border isn't cut off
              className="grui flex flex-col pt-5 pb-1"
              css={[styles.gapStyle, styles.moreOptions]}
            >
              <div
                css={[
                  styles.rowWrapper,
                  state.rowVisibility.from && indented && styles.indentedStyle,
                ]}
              >
                {!state.rowVisibility.from && children}
                <ToRow
                  isForwarding={forwarding}
                  ticketId={ticketId}
                  recipients={recipientsTo}
                  recipientsOpenUpward={recipientsOpenUpward}
                  key={`${rowKeyPrefix}-to`}
                  onFocus={propFunc(onActionClick, 'to')}
                  tabIndex="0"
                  isNote={isNote}
                  allowCreateNewContactByName={isNote && isNewTicket}
                />
                {state.actionsRow === 'to' && actions}
              </div>

              {(state.isNameDirty || state.rowVisibility.name) &&
                !isForwardingType && (
                  <div
                    css={[styles.rowWrapper, indented && styles.indentedStyle]}
                  >
                    <SimpleRow
                      type="name"
                      label="Name"
                      value={name}
                      onChange={handleNameChange}
                      key={`${rowKeyPrefix}-name`}
                      onFocus={propFunc(onActionClick, 'name')}
                      tabIndex="0"
                    />
                    {state.actionsRow === 'name' && actions}
                  </div>
                )}

              {state.rowVisibility.phone && (
                <div
                  css={[styles.rowWrapper, indented && styles.indentedStyle]}
                >
                  <SimpleRow
                    type="phone"
                    label="Phone"
                    value={phone}
                    onChange={onPhoneChange}
                    key={`${rowKeyPrefix}-phone`}
                    onFocus={propFunc(onActionClick, 'phone')}
                    tabIndex="0"
                  />
                  {state.actionsRow === 'phone' && actions}
                </div>
              )}

              {state.rowVisibility.cc && (
                <div
                  css={[styles.rowWrapper, indented && styles.indentedStyle]}
                >
                  <CcRow
                    recipients={recipientsCc}
                    ticketId={ticketId}
                    recipientsOpenUpward={recipientsOpenUpward}
                    key={`${rowKeyPrefix}-cc`}
                    onFocus={propFunc(onActionClick, 'cc')}
                    tabIndex="0"
                    isNote={isNote}
                  />
                  {state.actionsRow === 'c' && actions}
                </div>
              )}

              {state.rowVisibility.bcc && (
                <div
                  css={[styles.rowWrapper, indented && styles.indentedStyle]}
                >
                  <BccRow
                    recipients={recipientsBcc}
                    ticketId={ticketId}
                    recipientsOpenUpward={recipientsOpenUpward}
                    key={`${rowKeyPrefix}-bcc`}
                    onFocus={propFunc(onActionClick, 'bcc')}
                    tabIndex="0"
                    isNote={isNote}
                  />
                  {state.actionsRow === 'bcc' && actions}
                </div>
              )}

              {state.rowVisibility.subject && (
                <div
                  css={[styles.rowWrapper, indented && styles.indentedStyle]}
                >
                  <SimpleRow
                    type="subject"
                    label="Subject"
                    value={subject}
                    onChange={onSubjectChange}
                    key={`${rowKeyPrefix}-subject`}
                    onFocus={propFunc(onActionClick, 'subject')}
                    tabIndex="0"
                    inputDataTestId="subject"
                  />
                  {state.actionsRow === 'subject' && actions}
                </div>
              )}
            </div>
          </DraggableRowsWrapper>
        )}
      </div>
    </FocusContext.Provider>
  )
}

Expanded.propTypes = {
  recipientsOpenUpward: PropTypes.bool,
}

Expanded.defaultProps = {
  recipientsOpenUpward: false,
}

export default Expanded
