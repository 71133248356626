import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

import ReplyEmailForm from 'components/App/DesktopView/CommunicationForms/Reply/Email'
import ReplyNoteForm from 'components/App/DesktopView/CommunicationForms/Reply/Note'
import ReplyFacebookForm from 'components/App/DesktopView/CommunicationForms/Reply/Facebook'
import { selectIsAddingNote } from 'selectors/page'
import { doChangeEditorFocus } from 'ducks/editor'
import { useAi } from 'ducks/ai/hooks'
import { COMPOSER_TYPE } from 'ducks/ai/types'
import {
  selectIsTwitterTicket,
  selectIsFacebookTicket,
} from 'selectors/tickets/type'
import styles from './styles.less'

const FooterForm = ({ ticketId, className }) => {
  const dispatch = useDispatch()
  const { requestState: composerRequestState } = useAi(COMPOSER_TYPE)
  const isAddingNote = useSelector(selectIsAddingNote)
  const isTwitter = useSelector(selectIsTwitterTicket)
  const isFacebook = useSelector(selectIsFacebookTicket)
  const [editorLoaded, setEditorLoaded] = useState(false)

  const onEditorInit = useCallback(() => {
    setEditorLoaded(true)
  }, [])

  const onEditorFormFocus = useCallback(
    () => {
      dispatch(doChangeEditorFocus(true))
    },
    [dispatch]
  )

  const onEditorFormBlur = useCallback(
    () => {
      dispatch(doChangeEditorFocus(false))
    },
    [dispatch]
  )

  const onInit = useCallback(
    () => {
      setEditorLoaded(true)
      if (onEditorInit) {
        onEditorInit()
      }
    },
    [setEditorLoaded, onEditorInit]
  )

  const showCommentEditor = editorLoaded && isAddingNote
  const hasAIEditRequestState =
    composerRequestState.loading || composerRequestState.error

  let editor = null

  if (!showCommentEditor) {
    if (isTwitter) return null
    if (isFacebook) {
      editor = (
        <ReplyFacebookForm
          ticketId={ticketId}
          onEditorFormFocus={onEditorFormFocus}
          onEditorFormBlur={onEditorFormBlur}
          onEditorInit={onInit}
        />
      )
    } else {
      editor = (
        <ReplyEmailForm
          ticketId={ticketId}
          onEditorFormFocus={onEditorFormFocus}
          onEditorFormBlur={onEditorFormBlur}
          onEditorInit={onInit}
        />
      )
    }
  } else {
    editor = (
      <ReplyNoteForm
        ticketId={ticketId}
        onEditorFormFocus={onEditorFormFocus}
        onEditorFormBlur={onEditorFormBlur}
        onEditorInit={onInit}
      />
    )
  }

  return (
    <div
      className={cn(className, !editorLoaded && styles.HiddenForm)}
      css={hasAIEditRequestState && styles.hiddenComposer}
    >
      {editor}
    </div>
  )
}

export default FooterForm
