import { css } from '@emotion/react'

// HACK (jscheel): Reskinning a sui component for now
export const replyStyle = theme => css`
  background-color: ${theme.color.buttonInfo.background};
  border-color: ${theme.color.buttonInfo.border};
  color: ${theme.color.buttonInfo.text};

  &:hover {
    background-color: ${theme.color.buttonInfo.backgroundHover};
    border-color: ${theme.color.buttonInfo.borderHover};
    color: ${theme.color.buttonInfo.textHover};
  }

  &:active {
    background-color: ${theme.color.buttonInfo.backgroundPressed};
    border-color: ${theme.color.buttonInfo.borderPressed};
    color: ${theme.color.buttonInfo.textPressed};
  }

  &[disabled] {
    background-color: ${theme.color.buttonInfo.backgroundDisabled};
    border-color: ${theme.color.buttonInfo.borderDisabled};
    color: ${theme.color.buttonInfo.textDisabled};
  }
`

export const noteStyle = theme => css`
  background-color: ${theme.color.buttonNote.background};
  border-color: ${theme.color.buttonNote.border};
  color: ${theme.color.buttonNote.text};

  &:hover {
    background-color: ${theme.color.buttonNote.backgroundHover};
    border-color: ${theme.color.buttonNote.borderHover};
    color: ${theme.color.buttonNote.textHover};
  }

  &:active {
    background-color: ${theme.color.buttonNote.backgroundPressed};
    border-color: ${theme.color.buttonNote.borderPressed};
    color: ${theme.color.buttonNote.textPressed};
  }

  &[disabled] {
    background-color: ${theme.color.buttonNote.backgroundDisabled};
    border-color: ${theme.color.buttonNote.borderDisabled};
    color: ${theme.color.buttonNote.textDisabled};
  }
`

export const baseButtonStyle = theme => css`
  .sui .sui-inner {
    .ui.buttons {
      border-radius: 2rem;
      background: none !important;
      box-shadow: none;
      transition: box-shadow 0.2s ease-in-out;
      &:hover,
      &:active,
      &:disabled {
        background: none !important;
      }
      &:focus-within {
        box-shadow: 0 0 4px 0 ${theme.color.buttonInfo.backgroundHover}99;
      }
    }
    .ui.button {
      ${replyStyle(theme)};
      border-radius: 2rem 0 0 2rem !important;
      font-family: ${theme.font.primary};
      font-weight: ${theme.fontWeight.medium};
      height: 32px;
    }

    .ui.button.dropdown {
      ${replyStyle(theme)};
      border-radius: 0 2rem 2rem 0 !important;
      padding-left: 10px !important;
    }
    .ui.button .dropdown.icon {
      border-left: none !important;
    }

    /* HACK (jscheel): SUI is positioning our menu wrong, just forcing it for now since we will switch to internal-design-system eventually */
    .ui.buttons > .ui.dropdown:last-child .menu {
      left: 0 !important;
      right: auto !important;
    }

    .without-dropdown .ui.button {
      border-radius: 2rem !important;
    }
  }
`

export const noteButtonStyle = theme => css`
  .sui .sui-inner {
    .ui.buttons:focus-within {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    }
    .ui.button {
      ${noteStyle(theme)};
      border-radius: 2rem 0 0 2rem !important;
    }
    .ui.button.dropdown {
      ${noteStyle(theme)};
      border-radius: 0 2rem 2rem 0 !important;
    }
  }
`
