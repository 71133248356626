import { createRef, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEditorInstance, useEditorMentions } from 'ducks/editor/hooks'
import { isEmpty } from 'util/strings'

import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import CloseIcon from '@groovehq/internal-design-system/lib/assets/icons/Close'
import EmojiPicker from 'components/App/DesktopView/shared/EmojiPicker'
import NoteEditor from 'components/App/DesktopView/shared/Composer/Note/Editor'
import MentionSelector from 'components/MentionSelector'
import SendButton from 'components/App/DesktopView/CommunicationForms/Reply/shared/SendButton'
import styles from 'components/App/DesktopView/CommunicationForms/Reply/shared/styles.less'
import Attachments from 'components/Attachments'
import { selectMessageById } from 'ducks/tickets/selectors'
import { emptyObj } from 'util/objects'
import { doEditNote } from 'ducks/tickets/actions/doEditNote'

const NoteEdit = ({ ticketId, messageId, onCancelEdit }) => {
  const dispatch = useDispatch()
  const { body: messageBody, attachments } =
    useSelector(state => selectMessageById(state, messageId)) || emptyObj

  const editorScrollElement = createRef()
  const { editorWrapperRef: composerRef, editorInstance } = useEditorInstance()
  const { onKeyDown, onMention } = useEditorMentions({ editorInstance })

  const [body, setBody] = useState(messageBody)

  const onUpdate = useCallback(
    () => {
      dispatch(doEditNote(ticketId, messageId, body))
      onCancelEdit()
    },
    [dispatch, onCancelEdit, ticketId, messageId, body]
  )

  return (
    <div className={styles.form} ref={composerRef}>
      <div className="inner note note-edit" ref={editorScrollElement}>
        <div className="form-header-wrapper">
          <div className="form-header" />
        </div>
        <div className="form-editor-wrapper grui flex-2-1-auto flex-col">
          <MentionSelector
            legacy={false}
            scrollParentRef={editorScrollElement}
            editorInstance={editorInstance}
          />
          <NoteEditor
            content={body}
            contentKey={`edit-note-editor-${messageId}`}
            composerId={`edit-note-editor-${messageId}`}
            className="editor"
            onChange={setBody}
            onKeyDown={onKeyDown}
            onMention={onMention}
          />
          <div className="attachments">
            <Attachments attachments={attachments} />
          </div>
        </div>
        <div className="form-footer-wrapper">
          <div className="form-footer">
            <div className="form-footer-content">
              <div className="sendButtonContainer">
                <SendButton
                  className="note-button"
                  actionLabel="Update note"
                  dropdownVisible={false}
                  disabled={isEmpty(body)}
                  ticketId={ticketId}
                  isNote
                  isOpen={false}
                  prefersOpen={false}
                  onSendClick={onUpdate}
                />
              </div>
              <div className="grui flex flex-grow">
                <div className="left-side">
                  <div className="actions">
                    <EmojiPicker editor={editorInstance} direction="right" />
                  </div>
                </div>
                <div className="right-side">
                  <div className="actions">
                    <Tooltip
                      title="Cancel edit"
                      position="top"
                      strategy="fixed"
                    >
                      <Button
                        type="icon"
                        size="small"
                        onClick={onCancelEdit}
                        className="deleteButton"
                        data-test-id="DraftDeleteButton"
                      >
                        <CloseIcon />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoteEdit
