import { connect } from 'react-redux'
import { doSelectAllTickets } from 'actions/app'
import {
  doDeleteSelectedTickets,
  doBulkSpamSelectedTickets,
  doBulkUnreadSelectedTickets,
  doUnmarkBulkSelectionMode,
} from 'actions/tickets'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import { selectSearchTotalCountForCurrentQueryId } from 'ducks/searches/selectors'
import DetailPane from './view'

const select = state => ({
  shownTicketCount: selectSearchTotalCountForCurrentQueryId(state),
  ticketIds: selectSelectedTicketIds(state),
})

const perform = {
  onCancel: doUnmarkBulkSelectionMode,
  onDeleteClick: doDeleteSelectedTickets,
  onMarkAsSpamClick: doBulkSpamSelectedTickets,
  onMarkAsUnreadClick: doBulkUnreadSelectedTickets,
  onSelectAllShown: doSelectAllTickets,
}

export default connect(select, perform)(DetailPane)
