import { connect } from 'react-redux'
import { selectIsInboxBootstrapped } from 'selectors/app'
import { selectCurrentSearchIsError } from 'ducks/searches/selectors'

import { selectAgentHasMailboxAccess } from 'ducks/mailboxes/selectors/selectAgentHasMailboxAccess'
import { doHideLaunchBar } from 'actions/app'
import { selectShowInboxesEmptyPage } from 'selectors/trial_setup'
import { selectIsAccountBootstrapped } from 'ducks/accounts/selectors/selectAccount'
import { selectCurrentPayload } from 'selectors/location'
import { selectShouldHideFeaturesAnnoucement } from 'ducks/flags/selectors'
import Layout from './view'

const select = state => {
  return {
    hasMailboxAccess: selectAgentHasMailboxAccess(state),
    hasSearchErrored: selectCurrentSearchIsError(state),
    showEmptyInboxPage: selectShowInboxesEmptyPage(state),
    isInboxBootstrapped: selectIsInboxBootstrapped(state),
    isAccountBootstrapped: selectIsAccountBootstrapped(state),
    payload: selectCurrentPayload(state),
    shouldHideAnnouncement: selectShouldHideFeaturesAnnoucement(state),
  }
}

const perform = {
  doHideLaunchBar,
}

export default connect(select, perform)(Layout)
