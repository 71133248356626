import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { selectAgentHasMailboxAccess } from 'ducks/mailboxes/selectors/selectAgentHasMailboxAccess'
import {
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
} from 'selectors/page'
import {
  selectShowContinueForwardingSetupBar,
  selectShowContinueForwardingSetupPane,
} from 'selectors/layout'
import { selectIsCurrentDemoMailbox } from 'ducks/mailboxes/selectors/selectIsCurrentDemoMailbox'
import FilterBadges from 'components/ConversationList/Header/Search/FilterBadges'
import {
  selectEntityCountByQueryId,
  selectSearchByQueryId,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectIsOnSearchPage } from 'selectors/location'
import LayoutSwitcher from './LayoutSwitcher'
import EmptyFolder from './EmptyFolder'
import NoMailboxAccess from './NoMailboxAccess'
import styles from './styles.less'

const Inbox = () => {
  const queryId = useSelector(selectCurrentQueryId)
  const isNewEmail = useSelector(selectIsCreatingNewEmail)
  const isNewConversation = useSelector(selectIsLoggingNewConversation)
  const isNewEmailOrNewConversation = !!isNewConversation || !!isNewEmail
  const hasMailboxAccess = useSelector(selectAgentHasMailboxAccess)
  const showForwardingSetupBar = useSelector(
    selectShowContinueForwardingSetupBar
  )
  const showForwardingSetupPane = useSelector(
    selectShowContinueForwardingSetupPane
  )
  const { loaded: searchesLoaded, errored: searchesError } = useSelector(
    state => selectSearchByQueryId(state, queryId)
  )

  const isDemo = useSelector(selectIsCurrentDemoMailbox)

  const ticketCountsInFolder = useSelector(state =>
    selectEntityCountByQueryId(state, queryId)
  )
  const isOnSearchPage = useSelector(selectIsOnSearchPage)

  const folderHasNoTicket =
    searchesLoaded && !searchesError && ticketCountsInFolder === 0

  const shouldShowEmptyFolder =
    folderHasNoTicket &&
    !isOnSearchPage &&
    !isNewEmailOrNewConversation &&
    !showForwardingSetupBar &&
    !showForwardingSetupPane &&
    !isDemo

  if (!hasMailboxAccess) return <NoMailboxAccess />

  if (shouldShowEmptyFolder) {
    return <EmptyFolder />
  }

  return (
    <div className={cn('TicketingApp', styles.content)}>
      <FilterBadges />
      <LayoutSwitcher />
    </div>
  )
}

export default React.memo(Inbox)
